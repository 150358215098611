<template>
  <div class= "card custom-table-container">
      <base-table :data="users"
                  thead-classes="text-primary">
        <template slot-scope="{row}">
          <td>
            <p class="title">{{row.name}}</p>
          </td>
          <td class="td-actions text-right">
            <base-button type="link"  aria-label="edit button" @click="editButtonOnClick(row.uid)">
              <i class="tim-icons icon-pencil"></i>
            </base-button>
          </td>
        </template>
      </base-table>
  </div>
</template>
<script>
import {BaseTable} from '@/components'
import { mapActions, mapState } from 'vuex'
  export default {
    name: "UserRow",
    components: {
      BaseTable
    },
    props: {
      editButtonOnClick: Function
    },
    methods: {
      ...mapActions(['getUsers'])
    },
    computed: {
      ...mapState(['users'])
    },
    mounted() {
      this.getUsers()
    }
  }
</script>
<style>
.custom-table-container{
    max-height: 400px;
    overflow-y: auto;
    border-radius: 10px;
}
.custom-container-bordered{
    background-color: aquamarine; 
    overflow: hidden;
}
</style>