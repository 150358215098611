<template>
  <div class="row">
    <div class="col-md-3" >
    </div>
    <div class="col-md-6" >
      <edit-profile-form :model="model">
      </edit-profile-form>
    </div>
    <!-- <div class="col-md-4">
      <user-card :user="user"></user-card>
    </div> -->
  </div>
</template>
<script>
  import EditProfileForm from './Profile/EditProfileForm';
  import UserCard from './Profile/UserCard';
  import {mapState} from 'vuex';

  export default {
    components: {
      EditProfileForm,
      UserCard
    },
    data() {
      return {
        model: {
          name: this.$store.state.profile.name,
          taxID: this.$store.state.profile.taxID,
          email: this.$store.state.profile.email,
          phoneNumber: this.$store.state.profile.phoneNumber,
          address: this.$store.state.profile.address,
          zipCode: this.$store.state.profile.zipCode,
          locality: this.$store.state.profile.locality,
          country: this.$store.state.profile.country,
          state: this.$store.state.profile.state,
        }
      }
    }
  }
</script>
<style>
</style>
