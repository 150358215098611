<template>
<div class="container text-center mt-5" id="login">
    
    <div class="row text-center" v-if="loader" id="loader">
        <pulse-loader class="col-12 text-center text-withe" :loading="loader" :color="color" :height="height">Is Loading...</pulse-loader>    
    </div>

    <div class="vue-tempalte" v-if="!loader" >
        <form @submit.prevent = "signIn({email: email, password:pass})">
            <h3>Investors</h3>
            <div class="row">
                <user-row class="col-md-6" :editButtonOnClick="setSelectedUid" > </user-row>
                <files class="col-md-6" :uid="selecteduid" v-if="!!selecteduid"></files>
            </div>
        </form>
    </div>
</div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import UserRow from './Investors/UserRow';
import Files from './Files';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

    export default {
        data() {
            return {
                color: '#719949',
                height: '1000px',
                width: '1rem',
                email: '',
                pass: '',
                selecteduid: null
            }
        },
        components: {
            UserRow,
            Files,
            PulseLoader
        },
        computed: {
          users: {
            get () {
                return ['melisa', 'ariel', 'la mamo']
            }
          },
          error: null,
          user: null,
          ...mapState(['user', 'error', 'loader'])
        },
        methods: {
          ...mapActions(['signIn']),
          setSelectedUid(uid) {
              this.selecteduid = uid
          }
        }
    }
</script>
<style>
</style>