<template>
    <div>
        <div>
            
            <div class="row text-center" v-if="loader" id="loader">
                <pulse-loader class="col-12 text-center text-withe" :loading="loader" :color="color" :height="height">Is Loading...</pulse-loader>    
            </div>
            <div class="card" v-if="!loader">
              <div class="card-header">
                <h1 v-if="!uid">Public Documents</h1>
                </div>
                <div class="card-header">
                <h1 v-if="!!uid">Private Document</h1>
                </div>
                <div class="card-body">
                <form @submit.prevent="handleOnCLick(document)">

                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-2 col-form-label">Name</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" id="inputEmail3" v-model="document.name">
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-2 col-form-label">File</label>
                        <div class="col-sm-10">
                            <input type="file" ref="uplodeable"  class="form-control" @change="findDocument($event)">
                            <button type=""  class="btn-block btn-link btn-primary block" @click="$refs.uplodeable.click()">Choose File</button>
                        </div>
                    </div>

                    <input type="radio" id="yes" value="true" v-model="document.download">
                        <label for="yes">  Is not secured  </label>
                            <br>
                    <input type="radio" id="no" value="false" v-model="document.download">
                    <label for="no">  Is secured  </label>


                    <div class="form-group row mt-5">
                        <div class="col-sm-12">
                        <button type="submit" :disabled="document.urlTmp === null" class="btn-block btn-lg btn-primary">Upload</button>
                        </div>
                    </div>
                </form>
                </div>
            </div>
            <div class="card" v-if="!uid && !loader">
                <!-- Add User -->
                <h1>Investor list</h1>
                <div class="card-body">
                    <form @submit.prevent="addUserFile(investorsData)">
                        <div class="form-group row">
                            <label for="inputEmail3" class="col-sm-2 col-form-label">Name</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" id="inputEmail3" v-model="investorsData.name" disabled>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputEmail3" class="col-sm-2 col-form-label">File</label>
                            <div class="col-sm-10">
                                <input type="file" ref="uplodeable"  class="form-control" @change="findInvestorList($event)">
                                <button type=""  class="btn-block btn-link btn-primary block" @click="$refs.uplodeable.click()">Choose File</button>
                            </div>
                        </div>

                        <div class="form-group row mt-5">
                            <div class="col-sm-12">
                            <button type="submit" :disabled="investorsData.urlTmp === null" class="btn-block btn-lg btn-primary">Upload</button>
                            </div>
                        </div>
                    </form>
                </div>

                <!-- Crear User -->
                <h1>Create user</h1>
                <div class="card-body">
                    <form @submit.prevent="createUserFile(createUserData)">
                        <div class="form-group row">
                            <label for="inputEmail4" class="col-sm-2 col-form-label">Name</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" id="inputEmail4" v-model="createUserData.name" disabled>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputEmail4" class="col-sm-2 col-form-label">File</label>
                            <div class="col-sm-10">
                                <input type="file" ref="uplodeable"  class="form-control" @change="createUserExcel($event)">
                                <button type=""  class="btn-block btn-link btn-primary block" @click="$refs.uplodeable.click()">Choose File With New User</button>
                            </div>
                        </div>

                        <div class="form-group row mt-5">
                            <div class="col-sm-12">
                            <button type="submit" :disabled="createUserData.urlTmp === null" class="btn-block btn-lg btn-primary">Create User</button>
                            </div>
                        </div>
                    </form>
                </div>

                <!-- Delete User -->
                <h1>Delete user</h1>
                <div class="card-body">
                    <form @submit.prevent="deleteUserFile(deleteUserData)">
                        <div class="form-group row">
                            <label for="inputEmail5" class="col-sm-2 col-form-label">Name</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" id="inputEmail5" v-model="deleteUserData.name" disabled>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputEmail5" class="col-sm-2 col-form-label">File</label>
                            <div class="col-sm-10">
                                <input type="file" ref="uplodeable"  class="form-control" @change="deleteUserExcel($event)">
                                <button type=""  class="btn-block btn-link btn-primary block" @click="$refs.uplodeable.click()">Choose File to Delete User</button>
                            </div>
                        </div>

                        <div class="form-group row mt-5">
                            <div class="col-sm-12">
                            <button type="submit" :disabled="deleteUserData.urlTmp === null" class="btn-block btn-lg btn-primary">Delete User</button>
                            </div>
                        </div>
                    </form>
                </div>

                <!-- Update User -->
                <h1>Update user</h1>
                <div class="card-body">
                    <form @submit.prevent="updateUserFile(updateUserData)">
                        <div class="form-group row">
                            <label for="inputEmail6" class="col-sm-2 col-form-label">Name</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" id="inputEmail6" v-model="updateUserData.name" disabled>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputEmail6" class="col-sm-2 col-form-label">File</label>
                            <div class="col-sm-10">
                                <input type="file" ref="uplodeable"  class="form-control" @change="updateUserExcel($event)">
                                <button type=""  class="btn-block btn-link btn-primary block" @click="$refs.uplodeable.click()">Choose File to Update User</button>
                            </div>
                        </div>

                        <div class="form-group row mt-5">
                            <div class="col-sm-12">
                            <button type="submit" :disabled="updateUserData.urlTmp === null" class="btn-block btn-lg btn-primary">Update User</button>
                            </div>
                        </div>
                    </form>
                </div>




            </div> 
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
    name: 'files',
    data() {
        return {
            color: '#719949',
            height: '1000px',
            width: '1rem',
            document:{
                isPrivate: '',
                uid: '',
                date: '',
                name: '',
                file: null,
                uplodeable: null,
                urlTmp: null,
                fileName: null,
                download: true
            },
            investorsData:{
                date: '',
                name: 'UsersToUpload',
                file: null,
                uplodeable: null,
                urlTmp: null,
                fileName: null
            },
            createUserData:{
                date: '',
                name: 'investorsCreate',
                file: null,
                uplodeable: null,
                urlTmp: null,
                fileName: null
            },
            deleteUserData:{
                date: '',
                name: 'investorsDelete',
                file: null,
                uplodeable: null,
                urlTmp: null,
                fileName: null
            },
            updateUserData:{
                date: '',
                name: 'investorsUpdate',
                file: null,
                uplodeable: null,
                urlTmp: null,
                fileName: null
            },
        }
    },
    props: {
        uid: String
    },
    components: {
        PulseLoader
    },
    computed:{
    ...mapState(['loader']),

    },
    methods: {
        ...mapActions(['addFile', 'addUserFile', 'createUserFile', 'deleteUserFile', 'updateUserFile']),
        findDocument(event) {
            this.findFile(event, this.document)

        },
        handleOnCLick(document) {
            document.download = document.download == "false" ? false : true
            this.addFile(document)
        },
        findInvestorList() {
            this.investorsData.urlTmp = null
            this.investorsData.fileName = null
            this.findFile(event, this.investorsData)
        },
        createUserExcel() {
            this.createUserData.urlTmp = null
            this.createUserData.fileName = null
            this.findFile(event, this.createUserData)
        },
        deleteUserExcel() {
            this.deleteUserData.urlTmp = null
            this.deleteUserData.fileName = null
            this.findFile(event, this.deleteUserData)
        },
        updateUserExcel() {
            this.updateUserData.urlTmp = null
            this.updateUserData.fileName = null
            this.findFile(event, this.updateUserData)
        },
        findFile(event, object){
            this.document.name = event.target.files[0].name.split('.').slice(0, -1).join('.') ; 
            this.document.extension = event.target.files[0].name.split('.').pop();
            object.urlTmp = null
            object.fileName = event.target.files[0].name.split('.').slice(0, -1).join('.');
            object.uplodeable = event.target.files[0];
            object.file = event.target.files[0];
            object.uid = this.$props.uid
            object.isPrivate = !!this.$props.uid
            const reader = new FileReader();
            reader.readAsDataURL(object.uplodeable);
            reader.onload = (e) => {
                object.fileName = event.target.files[0].name.split('.').slice(0, -1).join('.')
,
                object.urlTmp = e.target.result;
               
            }

            
        },

    },
}
</script>
