<template>
  <card>
    <h5 slot="header" class="title">Edit Profile</h5>
    <div class="row">
      <div class="col-md-6 pr-md-1">
        <base-input label="Tax ID (disabled)"
                  placeholder="Tax ID"
                  v-model="model.taxID"
                  disabled>
        </base-input>
      </div>
      <div class="col-md-6 px-md-1">
        <base-input label="Name (disabled)"
                  placeholder="Name"
                  v-model="model.name"
                  disabled>
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <base-input label="Email address"
                  type="email"
                  placeholder="Email address"
                  v-model="model.email">
        </base-input>
      </div>
      <div class="col-md-4 pl-md-1">
        <base-input label="Phone Number"
                  placeholder="Phone Number"
                  v-model="model.phoneNumber">
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <base-input label="Address"
                  v-model="model.address"
                  placeholder="Home Address">
        </base-input>
      </div>
      <div class="col-md-4 pl-md-1">
        <base-input label="Postal Code"
                  placeholder="ZIP Code"
                  v-model ="model.zipCode">
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <base-input label="State"
                  v-model="model.state"
                  placeholder="State">
        </base-input>
      </div>
      <div class="col-md-4 pl-md-1">
        <base-input label="Locality"
                  v-model="model.locality"
                  placeholder="Locality">
        </base-input>
      </div>
      <div class="col-md-4 pl-md-1">
        <base-input label="Country"
                  v-model="model.country"
                  placeholder="Country">
        </base-input>
      </div>
    </div>
    <base-button class="btn-block" slot="footer" type="primary" @click="saveUser(model)" fill>Save</base-button>
  </card>
</template>
<script>
import {mapState, mapActions} from 'vuex'
  export default {
    props: {
      model: {
        type: Object,
        default: () => {
          return {};
        }
      }
    },
    computed: {
      ...mapState(['profile'])
    },
    methods: {
      ...mapActions(['saveUser'])
    }
  }
</script>
<style>
</style>
