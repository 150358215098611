<template>

<div class="container-fluid " id="login">
<div class="row text-center" v-if="loader" id="loader">
  <pulse-loader class="col-12 text-center text-withe" :loading="loader" :color="color" :height="height">Is Loading...</pulse-loader>
</div>
<div class="row" v-if="!loader">
   <div class="col-md-4"></div>
   <div class="col-md-4">
      <div class="card mt-sm-lg mt-5">
         <div class="card-header">
          <h3 class = "text-center">Sign In</h3>
         </div>
         <div class="card-body">
            <div class="row">
               <div class="col-md-12">
                 <form @submit.prevent = "signIn({email: email, password:pass})">
                     <div class="form-group">
                         <label>Email address</label>
                         <input type="email" class="form-control form-control-lg" v-model="email"/>
                     </div>
                     <div class="form-group">
                         <label>Password</label>
                         <input type="password" class="form-control form-control-lg" v-model="pass" />
                     </div>
                     <div class="card-footer">
                       <button type="submit" class="btn btn-primary btn-block">Sign In</button>
                        <p class="forgot-password text-right">
                           <router-link to="/resetPassword">Forgot password?</router-link>
                        </p>
                     </div>
                 </form>
               </div>
              </div>
               <strong class= "error-label">{{error}}</strong>
         </div>
         <!---->
      </div>
   </div>
  <div class="col-md-4"></div>
</div>
</div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
    export default {
        data() {
            return {
              email: '',
              pass: '',
              color: '#719949',
              height: '1000px',
              width: '1rem',
              pass: ''
            }
        },
        components: {
            PulseLoader
        },
        computed: {
          error: null,
          user: null,
          ...mapState(['user', 'error','loader'])
        },
        methods: {
          ...mapActions(['signIn'])
        }
    }
</script>
<style scoped>
#login {
  background-image: url('../../public/img/bioceres_office.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

.error-label {
    color: red;
}

</style>
