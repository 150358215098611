<template>

<div class="container-fluid " id="passwordRecovery">
<div class="row text-center" v-if="loader" id="loader">
  <pulse-loader class="col-12 text-center text-withe" :loading="loader" :color="color" :height="height">Is Loading...</pulse-loader>
</div>
<div class="row" v-if="!loader">
   <div class="col-md-4"></div>
   <div class="col-md-4">
      <div class="card mt-sm-lg mt-5">
         <div class="card-header">
          <h3 class = "text-center">Password recovery</h3>
         </div>
         <div class="card-body">
            <div class="row">
               <div class="col-md-12">
                 <form @submit.prevent = "recoverPasswordButton()">
                     <div class="form-group">
                         <label>Password</label>
                         <input type="password" class="form-control form-control-lg" v-model="pass"/>
                     </div>
                     <div class="form-group">
                         <label>Confirm Password</label>
                         <input type="password" class="form-control form-control-lg" v-model="passConfirm"/>
                     </div>
                     <div class="card-footer">
                       <button type="submit" class="btn btn-primary btn-block">Recover</button>
                     </div>
                 </form>
                 <strong class= "error-label">{{error}}</strong>
                 <strong class= "error-label">{{recoveryError}}</strong>
               </div>
              </div>
         </div>
      </div>
   </div>
   <div class="col-md-4"></div>
</div>
</div>
</template>
<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { mapState, mapActions } from 'vuex'
    export default {
        data() {
            return {
              error: '',
              pass: '',
              passConfirm: ''
            }
        },
        computed: {
          user: null,
            ...mapState(['user', 'recoveryError', 'loader'])
        },
        methods: {
          ...mapActions(['recoverPassword']),
          ifValidPassword() {
              var re = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}/;
              return re.test(this.pass)
          },
          recoverPasswordButton() {
              if (this.pass == this.passConfirm) {
                  this.error = '';
                  if (this.ifValidPassword() && this.$route.query.oobCode) {
                      const recoverRequest = {
                          newPassword: this.pass,
                          actionCode: this.$route.query.oobCode
                      }
                      this.recoverPassword(recoverRequest)
                  } else {
                      this.error = 'Please enter a password with at least 8 characters, a capital letter and a number'
                  }
              } else {
                  this.error = 'Passwords does not match.'
              }
          }
        },
        components: {
            PulseLoader
        }
    }
</script>
<style scoped>
#passwordRecovery {
  background-image: url('../../public/img/bioceres_office.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

.error-label {
    color: red;
}
</style>
