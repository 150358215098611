<template>

<div class="container-fluid " id="forgotPassword">
<div class="row text-center" v-if="loader" id="loader">
  <pulse-loader class="col-12 text-center text-withe" :loading="loader" :color="color" :height="height">Is Loading...</pulse-loader>
</div>
<div class="row" v-if="!loader">
   <div class="col-md-4"></div>
   <div class="col-md-4">
      <div class="card mt-sm-lg mt-5">
         <div class="card-header">
          <h3 class = "text-center">Password Recovery</h3>
          <p class = "text-center">Complete with your email to receive a new link to recover your password.</p>
         </div>
         <div class="card-body">
            <div class="row">
               <div class="col-md-12">
                 <form @submit.prevent="forgetPassword">
                     <div class="form-group">
                         <label>Email</label>
                         <input type="email" class="form-control form-control-lg" v-model="user.email" />
                     </div>
                     <div class="card-footer">
                      <button type="submit" class="btn btn-primary btn-block">Send</button>
                        <p class="forgot-password text-right">
                           <router-link to="/login">Sign In</router-link>
                        </p>
                     </div>
                 </form>
               </div>
              </div>
               <strong class= "error-label">{{error}}</strong>
         </div>
         <!---->
      </div>
   </div>
  <div class="col-md-4"></div>
</div>
</div>
</template>

<script>
import Vue from "vue"
import firebase from "firebase";
import { mapState } from 'vuex';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import VueSimpleAlert from "vue-simple-alert";
import router from '../router'

Vue.use(VueSimpleAlert);

export default {
  data() {
    return {
      user: {
        email: ''
      }
    };
  },
  computed: {
    user: null,
      ...mapState(['user', 'loader'])
  },
  methods: {
    forgetPassword() {
        firebase
        .auth()
        .sendPasswordResetEmail(this.user.email)
        .then(() => {
            this.$alert('Check your registered email to reset the password!','Good!','success')
            this.user = {
              email: ''
            }
            router.push("/login")
        }).catch((error) => {
          this.$alert(error,'Wrong!','warning')
        })
    }
  },
  components: {
      PulseLoader,
      VueSimpleAlert
  }
};
</script>
<style scoped>
#forgotPassword {
  background-image: url('../../public/img/bioceres_office.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

.error-label {
    color: red;
}

</style>
